$ouiTextPrimary: #424242;
$ouiTextSecondary: #29434e;
$ouiTextTertiary: #757575;

$OUIConfirmGreen: #43A047;
$OUIDarkGreen: #2b7f2f;
$OUIRelaxBlue: #0044ff;
$OUIRelaxBlueTwo: #0300d8;
$fGrey: #F4F4F4;
$V4Red: #FF4E50;

$LightBlue: #d5f2ff;

$DaveyGold: #b1976b;

$ouiBackgroundPrimary: #424242;
$ouiBackgroundSecondary: #29434e;
$ouibackgroundTertiary: #757575;
$ouibackgroundWhite: #ffffff;

$ouiButtonPrimary: #424242;
$ouiButtonPrimaryText: #ffffff;

$ouiFont: 'Lato';

$ExtraExtraLargeText: 36pt;
$ExtraLargeText: 26pt;
$LargeText: 20pt;
$MediumText: 16pt;
$SmallText: 13pt;
$ExtraSmallText: 10pt;
$ExtraExtraSmallText: 8pt;

$OffBlack: #1f1f1f;
$OffWhite: #dedede;
$OffGrey: #7b7b7b;
$HoverGrey: #6b6b6b;
$LightGrey: #6f6f6f;
$UltraLightGrey: #d4d4d4;
$ExtraLightGrey: #f3f3f3;
$DropGrey: #d8d8d8;
$OffRed: #F44336;
$DarkRed: #da3d31;
$BlueSteel: #50626A;

$RapuGrey: #636363;

$SpaceGrey: #555555;
$DeepSpaceGrey: #232323;


* {
	font-family: $ouiFont;
}


html {
  scroll-behavior: smooth;
}

.BlackSVGFill {
	color: black;
}

.Black {
	color: $OffBlack;
}

p, h1, h2, h3, h4 {
	opacity: inherit;
	margin: 0px;
	padding: 0px;
	color: $OffBlack;
}

p, li, label {
	font-size: $SmallText;
	line-height: 25px;
}

label {
	text-align: left;
}

label.TextCentre {
	text-align: center;
}

.Width50px {
	width: 50px
}

.Width70px {
	width: 70px
}

.Width75px {
	width: 75px
}

.Width90px {
	width: 90px;
	min-width: 90px;
}

.Width100px {
	width: 100px;
	min-width: 100px;
}

.Width120px {
	width: 120px;
	min-width: 120px;
}

.Width150px {
	width: 150px;
	min-width: 150px;
}

.MinWidth170px {
	min-width: 170px;
}

.Width180px {
	width: 180px;
	min-width: 180px;
}

.Width200px {
	width: 200px;
	min-width: 200px;
}

.Width260px {
	width: 260px;
	min-width: 260px;
	max-width: 260px;
}

.StrikeOutHover:hover {
	text-decoration: line-through;
}


.App {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

#root {
	display: flex;
	justify-content: center;
	background: $ExtraLightGrey;
	min-height: 100vh;
}

.MaxSizeBracket {
	max-width: 1200px;
	width: calc(100% - 80px);
}

.MaxSizeBracket.PaddingHoz40 {
	max-width: calc(1200px - 80px);
	width: calc(100% - 160px);
}

li {
	padding-bottom: 5px;
}

.ExtraSmall {
	font-size: $ExtraSmallText;
}

.ExtraExtraSmall {
	font-size: $ExtraExtraSmallText;
}

p.TabIndent {
	padding: 0px 10px;
}

ul {
	margin: 0px;
}

a {
	text-decoration: none;
	transition: 200ms;
}

a.ThinA, p.PillButton.Thin, .PillButton.Thin, label.Thin, h3.Thin {
	height: 25px !important;
	padding: 5px 10px;
	line-height: 10px;
}

a.Black {
	color: black;
}

a:hover {
	color: $HoverGrey;
}

h1, .NavH1 {
	font-size: $ExtraLargeText;
	color: $OffBlack;
	font-weight: 700;
}

h1.Large {
	font-size: $ExtraExtraLargeText;
}

h1.Oversize {
	font-size: $ExtraLargeText;
}

.Green {
	color: $OUIConfirmGreen;
}

.Red, .HoverRed:hover {
	color: $OffRed !important;
}

.NavH1.small{
	font-size: $MediumText;
}

.LightWeight {
	font-weight: 300 !important;
}

.HeavyWeight {
	font-weight: 700;
}

h2, .NavH2 {
	font-size: $LargeText;
	color: black;
	line-height: 40px;
	font-weight: 300;
}

h1.Blue, h2.Blue, h3.Blue, .Navh2.Blue, .Blue {
	color: $OUIRelaxBlue;
}

.Gold {
	color: $DaveyGold;
}

.V4Red {
	color: $V4Red;
}

.Grey {
	color: $OffGrey;
}

h3, .NavH3 {
	font-size: $MediumText;
	font-weight: 300;
}

button, .NavButton {
	font-size: $SmallText;
	text-align: center;
	background: none;
	color: $OffBlack;
	border: none;
	border-radius: 3px;
	padding: 7px;
	justify-content: center;
}

.NavButton {
	align-items: center;
}

button.Secondary {
	background: white;
	color: $ouiTextSecondary;
	border: 1px solid $ouiTextSecondary;
}

button.ButtonSelected{
	opacity: 1;
}

button.LeftAlignFlexButton {
	text-align: left;
	margin-left: 0px;
	padding-left: 0px;
	font-size: $SmallText;
	font-weight: 700;
}

.PillButton {
	border: 2px solid $OffBlack;
	text-align: center;
	padding: 8px 0px;
	border-radius: 10px;
	opacity: 1;
	transition: 200ms;
	line-height: 20px;
	height: 40px;
	font-size: $ExtraSmallText !important;
	font-weight: 700;
	padding-left: 10px;
	padding-right: 10px;
	justify-content: center;
}

.PillButton.Thicc {
	height: 50px;
	align-items: center;
}

.PillButton.AutoHeight {
	height: auto;
}

button.PillButton:hover {
	cursor: pointer;
	border: 2px solid $OffGrey;
	color: $OffGrey;
}

button.PillButton.NoHover, button.PillButton.Disabled {
	cursor: default;
}

button.NoPaddingNoMargin {
	padding: 0px;
	margin: 0px;
}

.NoPaddingLeft {
	padding-left: 0px;
}

.TextButton:hover {
	color: $OffGrey;
}

.LineBreakBeneath, .BorderBottom {
	width: 100%;
	padding-bottom: 10px;
	border-bottom: 2px solid $OffBlack;
	border-radius: 0px;
}

.BorderBottom {
	padding: 0px;
}

.BorderBottom.Blue {
	border-bottom: 4px solid $OUIRelaxBlue;
}

.BorderBottom.Light {
	border-bottom: 2px solid $OffGrey;
}

.LineBreakBeneath.UltraLight {
	border-bottom: 1px solid $UltraLightGrey;
}

.LineBreakBeneath.Light, .LineBreakBeneath.LightLast {
	border-bottom: 1px solid $OffGrey;
}

.LineBreakBeneath.Blue {
	border-bottom: 1px solid $OUIRelaxBlue;
}

.LineBreakBeneath.NoPaddingBottom {
	padding-bottom: 0px;
}

.LineBreakAbove {
	padding-top: 10px;
	border-top: 2px solid $OffBlack;
}

.LineBreakAbove.X2 {
	padding-top: 20px;
}

.LineBreakAbove.Light {
	border-top: 1px solid $OffGrey;
}

.LineBreakAbove.UltraLight {
	border-top: 1px solid $UltraLightGrey;
}

.PillButton.Light {
	border: 2px solid $OffGrey;
	color: $OffGrey;
}

.PillButton.ExtraSmall {
	width: 100px;
}

.PillButton.Small {
	width: 150px;
	max-width: 150px;
}

a.Small, h3.Small {
	width: 126px !important;
}

button.PillButton.Medium, .PillButton.Medium, .FlexContainer.MediumWidth {
	width: 200px;
	max-width: 200px;
}

h3.PillButton.Medium, a.PillButton.Medium {
	width: 176px;
	max-width: 176px;
}

.SimpleButton {
	opacity: 1;
	transition: 200ms;
}

.SimpleButton:hover {
	opacity: 0.7;
}




.PillButton.Medium.Less20 {
	width: 178px;
	min-width: 178px;
}

.PillButton.Large, .FlexContainer.LargeWidth {
	width: 300px;
	max-width: 300px;
}

.PillButton.ExtraLarge {
	width: 400px;
	max-width: 400px;
}

.SmallRound {
	border-radius: 5px !important;
}

.MediumRound {
	border-radius: 10px !important;
}

.SuperRound {
	border-radius: 20px !important;
}

.PaddingHoz0 {
	padding-left: 0px;
	padding-right: 0px;
}

.PaddingHoz10 {
	padding-left: 10px !important;
	padding-right: 10px !important;
}

.PaddingHoz20 {
	padding-left: 20px !important;
	padding-right: 20px !important;
}

.FlexContainer.PaddingHoz20 {
	width: calc(100% - 40px)
}

.PaddingHoz40 {
	padding-left: 40px;
	padding-right: 40px;
}

.PaddingVert20 {
	padding-top: 20px;
	padding-bottom: 20px;
}

.PaddingVert10 {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

.PaddingVert40 {
	padding-top: 40px;
	padding-bottom: 40px;
}

.NavButton.PillButton.Large {
	width: 280px;
}

.PillButton.None {
	border: 2px solid transparent;
	background: transparent;
	color: black;
}

.PillButton.None:hover {
	border: 2px solid $OffGrey;
	background: transparent;
	color: black;
}

.PillButton.Submit, .PillButton.Light.GreenOnHover:hover, .PillButton.Green,  .PillButton.Green:hover {
	border: 2px solid $OUIConfirmGreen;
	background: $OUIConfirmGreen;
	color: white;
}

.PillButton.OutlineGreen, .OutlineGreen, .PillButton.OutlineGreen:hover {
	border: 2px solid $OUIConfirmGreen;
	background: white;
	color: $OUIConfirmGreen;
}

.PillButton.Submit:hover, .PillButton.Green:hover, .PillButton.Green.SythHover, .PillButton.OutlineGreen:hover {
	border: 2px solid $OUIDarkGreen;
	background: $OUIDarkGreen;
	color: white;
}

.PillButton.Green.NoHover:hover {
	border: 2px solid $OUIConfirmGreen;
	background: $OUIConfirmGreen;
	color: white;
}

.PillButton.OutlineGreen.NoHover, .PillButton.OutlineGreen.NoHover:hover {
	border: 2px solid $OUIConfirmGreen;
	background: white;
	color: $OUIConfirmGreen;
}

.PillButton.Blue {
	border: 2px solid $OUIRelaxBlue;
	background: $OUIRelaxBlue;
	color: white;
}

.PillButton.Black {
	border: 2px solid black;
	background: black;
	color: white;
}

.PillButton.Black:hover {
	border: 2px solid $OUIRelaxBlue;
	background: $OUIRelaxBlue;
	color: white;
}

.PillButton.WhiteBorder {
	border: 2px solid white;
}

.PillButton.Blue:hover {
	background: #2a52c1;
	color: white;
	border: 2px solid #2a52c1;
}

.PillButton.Blue.NoHover:hover {
	border: 2px solid $OUIRelaxBlue;
	background: $OUIRelaxBlue;
	color: white;
}

.PillButton.InverseBlue {
	border: 2px solid $OUIRelaxBlue;
	background: white;
	color: $OUIRelaxBlue;
}

.PillButton.Red, .PillButton.Red.NoHover:hover {
	border: 2px solid $OffRed;
	background: $OffRed;
	color: white;
}

.OutlineRed, .PillButton.OutlineRed, .PillButton.OutlineRed.NoHover:hover, .HoverOutlineRed:hover {
	border: 2px solid $OffRed !important;
	background: white !important;
	color: $OffRed !important;
}

.PillButton.Red:hover, .PillButton.OutlineRed:hover {
	border: 2px solid $DarkRed !important;
	background: $DarkRed !important;
	color: white !important;
}

.PillButton.InverseBlue:hover {
	border: 2px solid $OUIRelaxBlue;
	background: $OUIRelaxBlue;
	color: white;
}

.PillButton.OutlineBlue {
	border: 2px solid $OUIRelaxBlue;
	background: white;
	color: $OUIRelaxBlue;
}

.PillButton.OutlineBlue:hover {
	border: 2px solid $OUIRelaxBlue;
	background: $OUIRelaxBlue;
	color: white;
}

.PillButton.OutlineBlue.NoHover:hover {
	border: 2px solid $OUIRelaxBlue;
	background: white;
	color: $OUIRelaxBlue;
}

.PillButton.OutlineV4Red {
	border: 2px solid $V4Red;
	background: white;
	color: $V4Red;
	opacity: 1;
	transition: 200ms;
}

.PillButton.OutlineV4Red:hover {
	opacity: 0.7;
}

.PillButton.OutlineWhite {
	border: 2px solid white;
	background: transparent;
	color: white;
}

.PillButton.OutlineBlack {
	border: 2px solid black;
	background: transparent;
	color: black;
}

.PillButton.OutlineWhite:hover {
	border: 2px solid $BlueSteel;
	background: white;
	color: $BlueSteel;
}

.PillButton.OutlineWhite.GreyOutlineHover:hover {
	border: 2px solid $OffGrey;
	background: white;
}

.PillButton.OutlineWhite.NoHover:hover {
	border: 2px solid white;
	background: transparent;
	color: white;
}

.PillButton.OutlineGrey, .OutlineGreyOnHobver:hover {
	border: 2px solid $OffGrey;
	background: white;
	color: $OffGrey;
}

.PillButton.OutlineGrey {
	border: 2px solid $OffGrey;
	background: white;
	color: $OffGrey;
}

.OutlineLightGrey {
	border: 2px solid $UltraLightGrey;
	background: white;
	color: $UltraLightGrey;
}

.PillButton.OutlineGrey:hover, .OutlineGrey.Hoverable:hover {
	border: 2px solid $OUIRelaxBlue;
	background: $OUIRelaxBlue;
	color: white;
}

.PillButton.OutlineGrey.GreyHover:hover {
	border: 2px solid $OffBlack;
	background: white;
	color: $OffBlack;
}

.PillButton.OutlineGrey.RedHover:hover, .PillButton.RedHover:hover {
	border: 2px solid $OffRed;
	background: $OffRed;
	color: white;
}

.PillButton.OutlineGrey.Disabled:hover, .PillButton.OutlineGrey.NoHover:hover {
	border: 2px solid $OffGrey;
	background: white;
	color: $OffGrey;
}

.PillButton.FullGrey, .PillButton.FullGrey:hover, .PillButton.Grey, .PillButton.Grey:hover {
	border: 2px solid $OffGrey;
	background: $OffGrey;
	color: white;
}

.PillButton.Grey.GreenHover:hover, .PillButton.OutlineGrey.GreenHover:hover {
	border: 2px solid $OUIDarkGreen;
	background: $OUIDarkGreen;
	color: white;
}

.PillButton.OutlineGrey.OutlineBlueHover:hover {
	border: 2px solid $OUIRelaxBlue;
	background: white;
	color: $OUIRelaxBlue;
}

.PillButton.WhiteBackground, .PillButton.WhiteBackground:hover {
	background: white !important;
}

.PillButton.Micro {
	font-size: $ExtraExtraSmallText !important;
	line-height: 10px;
	padding: 5px;
	height: 10px !important;
	width: 100px;
}






/* DAVEY */

.DaveyProfile, .ProfileImageContainer {
	height: 200px;
}


.DaveyGold {
	color: $DaveyGold;
}

.Overpass {
	font-family: 'Overpass';
}

.SpacingWide {
	letter-spacing: 3px;
}

.SpacingExtraWide {
	letter-spacing: 7px;
}

.DaveyLogoTextOne {
	font-size: 14pt;
	color: black;
}

.DaveyLogoTextTwo {
	font-size: 12pt;
	line-height: 15px;
}

.ThinLogoDivider {
	width: 150px;
	height: 1px;
	background: $DaveyGold;
}

.ThinLogoDivider.Small {
	width: 40px;
}

.DownDivider {
	width: 1px;
	height: 20px;
	background: $DaveyGold;
}


.Right1px {
	margin-left: 1px;
}

.MedWidthText {
	width: 70%;
}

.NavItems:last-child {
	margin-right: 40px;
}

.NavItemSubMenu {
	padding-bottom: 10px;
	z-index: 99999;
	width: 150px;
	box-shadow: 5px 10px #f1f1f1;
}

.SquareInput {
	background: #e6e6e6;
	color: $OffBlack;
	border-radius: 5px;
	padding: 10px;
}

.SquareTextArea {
	min-height: 150px;
}

.SquareInput.Regular {
}

.DaveyButton {
	width: 200px;
	padding: 20px;
	background: $SpaceGrey;
	color: white;
	font-weight: bold;
	border-radius: 5px;
}

.DaveyButton.Disabled {
	opacity: 0.5;
}




.MDEWrapper {

	p {
		margin-bottom: 10px !important;
	}

	h3 {
		margin-top: 40px;
		margin-bottom: 10px;
	}

	a {
		color: $OUIRelaxBlue;
		font-weight: bold;
		text-decoration: underline !important;
	}
}

strong {
	margin-bottom: 20px;
}



/* Footer */

.FooterContainer {
	width: 100%;
}


.V4Icon {
	width: 60px;
	height: 60px;
	opacity: 1;
}


.ListingsPageMap, .SingleListingMap, .ListingsPageMapSandboxId, .RecentSalesMap, .ComparableSalesMap {
	width: 100%;
	height: calc(100vh - 105px);
	display: inline-block;
	position: relative;
	height: 300px;
}



.svgInheritColour {
}


.svgInheritColour.Gold {
	fill: $DaveyGold;
}






@media only screen and (max-width: 1200px) {

	.HideUnder1200 {
		display: none !important;
	}


}


@media only screen and (max-width: 1000px) {

	.HideUnder1000 {
		display: none !important;
	}

}


@media only screen and (max-width: 650px) {

	.ProfileImageContainer {
		width: 100%;
		max-width: 100%;
		height: 150px;
	}

	.DaveyProfile {
		height: 150px;
	}

	.MedWidthText {
		width: 80%;
	}

	.FooterContainer {
		align-self: center;
	}

}























input, .FlexInput, .FlexInputFull, textarea {
	background: none;
	border: none;
	border-radius: 0px;
	font-size: $SmallText;
	line-height: 25px;
	color: $ouiTextPrimary;
}

textarea {
	resize: vertical;
	border: none;
	min-height: 50px;
	border-bottom: 1px solid black;
}

input {
	height: 35px;
}

.FlexInput, .FlexInputFull, .FlexInputV2 {
	border: none;
	border-bottom: 1px solid $ouiBackgroundSecondary;
	height: 25px !important;
	padding: 0px;
	width: 100%;
}

.FlexInputV2.Minus200 {
	width: calc(100% - 200px)
}

.FlexInputV2.TextArea {
	border: 1px solid $ouiBackgroundSecondary;
	width: calc(100% - 2px);
}

.FlexInputV2.TextArea.FiveRows {
	min-height: 100px;
}

.FlexInputV2.UltraLight {
	border-bottom: 1px solid $UltraLightGrey;
}

.FlexInputV2.Box {
	border: 1px solid $ouiBackgroundSecondary;
	padding: 10px;
	border-radius: 5px;
}

.InputHeight {
	height: 26px;
}

.PaddingBottom3px {
	padding-bottom: 3px;
}

.FlexInput {
	flex: 1;
}

.FlexInputV2.Invalid {
	border-color: red;
	color: red;
}

.FlexInputV2.Large {
	font-size: $LargeText;
	height: 40px !important;
}

form {
	width: 100%;
}

option, select {
	font-size: $SmallText;
}

.primary {
	color: $ouiTextPrimary;
}

.secondary {
	color: $ouiTextSecondary;
}

.tertiary {
	color: $ouiTextTertiary;
}

.tertiarySmallSpaceAbove {
	margin-top: 25px;
}

.tertiaryMediumSpaceAbove {
	margin-top: 50px;
}

.tertiaryLink, .tertiaryLink:hover {
	transition: 200ms;
}

.tertiaryLink:hover {
	color: $ouiTextSecondary;
}


.ExtraSmall {
	font-size: $ExtraSmallText;
}

.TextBold, .BoldText {
	font-weight: 700;
}

.WhiteText {
	color: white;
}

.LightText {
	color: $LightGrey;
	transition: 200ms;
}

button.LightText:Hover, .LightText.Clickable:Hover {
	color: $OffBlack
}

.ghostText {
	color: transparent;
}

.TextRight {
	text-align: right;
}

.TextLeft {
	text-align: left;
}

.TextCentre {
	text-align: center;
}

.FlexHeadings {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	flex-direction: row;
	width: 100%;
}

.FlexHeadings.Column {
	flex-direction: column;
}

.FlexHeadings.FlexEnd {
	justify-content: flex-end;
}


.Inline {
	display: inline;
}

.Fixed {
	position: fixed;
}

.Absolute {
	position: absolute;
	z-index: 999;
}

.z10 {
	z-index: 10;
	position: relative;
}

.z100 {
	z-index: 100;
	position: relative;
}

.Z9999{
	z-index: 9999 !important;
}

.Z99999{
	z-index: 99999 !important;
}

.Z99{
	z-index: 99;
}

.Z99999{
	z-index: 99999;
}

.SpaceGreyBackground {
	background: $SpaceGrey !important;
}

.SpaceGreyBackground.Deep {
	background: $DeepSpaceGrey !important;
}




.FlexRight:last-child {
	margin-right: 0px !important;
}

.FlexHeading, .FlexInput {
	flex: 2 1 47.5%;
	max-width: 47.5%;
}

.FlexHeadingFull, .FlexInputFull {
	flex: 1;
	max-width: 100%;
	width: 100%;
}

.FlexInputH2 {
	font-size: $LargeText;
	color: $OffBlack;
	line-height: 40px;
	padding-bottom: 5px;
	margin-top: 5px;
}

.FlexInputLabel {
	margin-right: 10px;
	color: $OffGrey;
}

.NoMarginTop {
	margin-top: 0px !important;
}

.NoMarginBottom {
	margin-bottom: 0px !important;
}

.UnderLineText {
	border-bottom: 1px solid $OffBlack;
	padding-bottom: 5px;
}

.SimpleDrop {
	box-shadow: 2px 2px 2px 2px $DropGrey;
	transition: 200ms;
}

.SimpleDrop.Extended {
	box-shadow: 0 0 18px 0 rgba(0,0,0,.26)
}



/* Overlays */

.OverlayContainer {
	z-index: 9990;
	display: inline;
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0px;
	left: 0px;
	background: rgba(0, 0, 0, 0.3); 
}

.OverlayContainer.PrimaryOverlay {
	z-index: 99999;
}


.OverlayContainer.Clicker {
	background: rgba(0, 0, 0, 0);
	cursor: pointer;
	z-index: 9999;
}

.OverlayModal {
	background: white;
	width: 70% !important;
	height: calc(80vh - 80px);
	display: flex;
	margin: auto;
	margin-top: 10vh;
	padding: 40px;
	border-radius: 10px;
	z-index: 999999;
	transition: 500ms;
}

.OverlayModal.AutoHeight {
	height: auto;
}

.OverlayModal.ExtraHeight {
	margin-top: 5vh;
	height: calc(90vh - 80px);
}

.OverlayModal.SmallerHeight {
	margin-top: 25vh;
	height: calc(50vh - 80px);
}

.OverlayModal.Wide {
	width: 85% !important;
}

.OverlayModal.NoPaddingVert {
	padding-top: 0px;
	padding-bottom: 0px;
	margin-top: 15vh;
}

.OverlayModal.Thin {
	width: 450px !important;
}


/* Alignment */


.AlignBottom {
	align-items: flex-end;
}


.Clickale {
	opacity: 1;
	transition: 200ms;
}

.Clickable:hover {
	opacity: 0.8;
	cursor: pointer;
}

.Clickable.X2:hover {
	opacity: 0.4;
	cursor: pointer;
}

.NoHover:hover {
	cursor: default;
}





/* 			OUI Containers 			*/

.primaryLevelContainer, .PrimaryLevelContainer, .PLC {
	display: inline-block;
	position: relative;
	width: 100%;
	padding: 0px;
	background: white;
	min-height: 100vh;
	transition: 500ms;
}

.PLC.Flex, .FlexRow {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: row;
}

.Flex.Column {
	flex-direction: column;
}

.ColumnReverse {
	flex-direction: column-reverse !important;
}

.secondaryLevelContainer, .SecondaryLevelContainer, .SLC {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 0px 100px 100px 60px;
	max-width: 1750px;
	margin: auto;
}

.SLC.ExtraWidePadding {
	padding: 0px 120px 100px 120px;
}

.SLC.ExtraWide {
	padding: 0px 0px 100px 30px;
}

.SLC.ExtraThinPadding {
	padding: 0px 80px 100px 80px;
}

.SLC.InternalSLC {
	width: calc(100% - 70px);
	padding: 0px;
	top: 0px;
	height: 85vh;
	display: flex;
	align-items: flex-end;
}

.SLC.InternalSLC.ExtraThinPadding {
	width: calc(100% - 120px);
}

.SLC.InternalSLC.slimline {
	width: calc(100% - 160px);
}

.SLC.Absolute {
	position: absolute;
}

.tertiaryLevelContainer, .TertiaryLevelContainer, .TLC {
	width: 100%;
	display: inline-block;
	position: relative;
	margin: 25px 0px 0px 0px;
}

.TLC.Max100 {
	max-height: 100vh;
	overflow: hidden;
}

.SLC.FullWidthRight {
	width: calc(100% - 40px);
	padding-right: 0px;
}

.SLC.NoPaddingBottom {
	padding-bottom: 0px;
}

.tertiaryLevelContainer, .FlexContainer {
	display: flex;
	flex-direction: row;
}

.FlexContainer.AutoHeight {
	height: auto;
}

.NoMarginNoPadding {
	padding: 0px;
	margin: 0px;
}

.Flex50 {
	width: 50%;
}

.FlexContainer.Tile {
	width: 550px;
	min-width: 550px;
	height: 200px;
}

.FlexContainer.Column {
	flex-direction: column;
}

.FlexContainer.Row.requested {
	background: $LightGrey;
}

.FlexContainer.Wrap {
	flex-wrap: wrap;
}

.FlexContainer.FullBlue {
	background: $OUIRelaxBlueTwo;
}

.FlexContainer.OutlineBlack, .FlexContainer.OutlineBlack.NoHover {
	border: 2px solid black;
	color: black;
}

.FlexContainer.OutlineLightGrey, .FlexContainer.OutlineLightGrey.NoHover {
	border: 2px solid $UltraLightGrey;
	color: $UltraLightGrey;
}

.FlexContainer.OutlineGrey, .FlexContainer.OutlineGrey.NoHover {
	border: 2px solid $LightGrey;
	color: $LightGrey;
}

.FlexContainer.OutlineGrey.NoHover:hover {
	color: $LightGrey;
	border: 2px solid $LightGrey !important;
}

.FlexContainer.OutlineBlue {
	border: 2px solid $OUIRelaxBlueTwo;
}

.FlexContainer.FitContent {
	height: fit-content;
}

.OverFlowX, .OverflowX {
	overflow-x: scroll;
	overflow-y: hidden;
}

.OverFlowY {
	overflow-y: scroll;
}

.Flex {
	display: flex;
	flex: 1;
}

.FlexStart {
	justify-content: flex-start;
}

.AlignStart {
	align-items: flex-start;
}

.FlexEnd {
	justify-content: flex-end;
}

.MarginAuto {
	margin: auto;
}

.AlignEnd {
	display: flex;
	align-items: flex-end;
}

.FlexWrap {
	flex-wrap: wrap;
}

.AlignCentre {
	align-items: center;
}

.AlignStart {
	align-items: flex-start;
}

.JustifyCentre {
	justify-content: center;
}

.AlignSelfEnd {
	align-self: flex-end;
}

.AlignSelfCentre {
	align-self: center;
}

.FlexContainerInner {
	flex: 1;
}

.FlexContainerInner:first-child {
	padding-right: 50px;
}

.FlexContainerInner:last-child {
	padding-left: 50px;
}

.FlexContainerInner.TwoThirds {
	flex: 2;
}

.PartnerViewSpaceTop {
	padding-top: 320px;
}

.width40, .Width40 {
	width: 40%;
}

.width50, .Width50 {
	width: 50%;
}

.Width50.SpaceRight, .Width50.SpaceLeft {
	width: calc(50% - 10px);
}

.Width50.SpaceRightX2, .Width50.SpaceLeftX2 {
	width: calc(50% - 20px);
}

.width60, .Width60 {
	width: 60%;
}

.width65, .Width65 {
	width: 65%;
}
.width70, .Width70 {
	width: 70%;
}

.width75, .Width75 {
	width: 75%;
}

.width80, .Width80 {
	width: 80%;
}

.width100, .Width100, .FullWidth {
	width: 100%;
	max-width: 100%;
}

.FullWidth.Minus20 {
	width: calc(100% - 20px) !important;
}

.FullWidth.Minus40 {
	width: calc(100% - 40px) !important;
}

.FullWidth.Minus80 {
	width: calc(100% - 80px) !important;
}

.Width500px {
	width: 500px;
}

.Width50 {
	width: 50%;
}

.Flex05 {
	flex: 0.5;
}

.Flex075 {
	flex: 0.75;
}

.Flex1, .Flex100 {
	flex: 1;
}

.Flex150 {
	flex: 1.5;
}

.Flex200 {
	flex: 2;
}

.Flex250 {
	flex: 2.5;
}

.Flex300 {
	flex: 3;
}

.Flex350 {
	flex: 3.5;
}

.Flex400 {
	flex: 4;
}

.Flex450 {
	flex: 4.5;
}

.Flex500 {
	flex: 5;
}

.Flex550 {
	flex: 5.5;
}

.ObjectFit {
	object-fit: cover;
	max-width: 100%;
}

.ObjectFit.Contain {
	object-fit: contain;
	max-width: 100%;
}

.Width100 {
	max-width: 100px;
}

.Width200 {
	max-width: 200px;
}

.Width250 {
	width: 250px;
	max-width: 250px;
}

.Width300px {
	width: 300px;
	max-width: 300px;
}

.Width325px {
	width: 325px;
	max-width: 325px;
}

.Width350px {
	width: 350px;
	max-width: 350px;
}

.Width400px {
	width: 400px;
	max-width: 400px;
}

.Width450px {
	width: 450px;
	max-width: 450px;
}

.Width500px {
	width: 500px;
	max-width: 500px;
}

.Height50px {
	min-height: 50px;
	max-height: 50px;
}

.Height60px {
	min-height: 60px;
	max-height: 60px;
}

.Height75px {
	min-height: 75px;
	max-height: 75px;
}

.Height80px {
	min-height: 80px;
	max-height: 80px;
}

.Height175px {
	min-height: 175px;
	max-height: 175px;
}

.Height150px {
	min-height: 150px;
	max-height: 150px;
}

.Height250px {
	min-height: 250px;
	max-height: 250px;
}

.Height200px {
	min-height: 200px;
	max-height: 200px;
}

.Height300px {
	min-height: 300px;
	max-height: 300px;
}

.MinHeight475px {
	min-height: 475px;
}

.MaxHeight275px {
	max-height: 275px;
}

.Height55{
	max-height: 55vh;
	min-height: 55vh;
}

.HeightFitContent {
	height: fit-content;
}

.FlexRowItem {
	flex: 1;
	text-align: left;
	padding: 15px 10px;
}

.FlexRowItem.Wide {
	flex: 1.5;
}

.FlexRowItem.Header {
	border-bottom: 2px solid $OffBlack;
	padding-bottom: 10px;
	margin-bottom: 25px;
	font-weight: 700;
}

.FlexRowItem.TopRow {
	padding-top: 0px;
	padding-bottom: 0px;
}

.simpleRowContainer {
	width: 100%;
	display: flex;
	border-bottom: 1px solid $ouibackgroundTertiary;
	margin: 0px 0px 15px 0px;
	padding: 10px 0px 10px 0px;
	justify-content: space-between;
}

.simpleRowContainer.ColumnRow {
	flex-direction: column;
}

.OUIRowMostRecent_true {
	border-bottom: 2px solid $ouiBackgroundPrimary;
	margin-bottom: 25px;
}

.OUINoSidePadding {
	padding-left: 0px;
	padding-right: 0px;
	width: 100%;
}

.InternalPaddingSmall {
	width: calc(100% - 120px);
	padding: 40px;
}

.InternalPaddingMedium {
	width: calc(100% - 100px);
	padding: 30px;
}

.InternalPaddingExtraSmall {
	width: calc(100% - 80px);
	padding: 20px 40px;
}

.InternalPaddingSmall.ThinTopBottom {
	padding-top: 20px;
	padding-bottom: 20px;
}

.InternalPaddingSmall.TopSection {
	padding: 40px 40px 0px 40px;
}

.InternalPaddingSmall.MiddleSection {
	padding: 0px 40px 0px 40px;
}

.InternalPaddingSmall.BottomSection {
	padding: 0px 40px 40px 40px;
}

.LightGreyBackground {
	background: $fGrey !important;
}

.WhiteBackground {
	background: white;
}

.LightBlueBackground {
	background: $LightBlue;
}

.SandboxNotifier {
	padding: 10px 20px;
	max-width: 1750px;
	margin: auto;
	flex: 1;
}

.SandboxNotifier.ActualSandbox {
	padding: 10px 100px 10px 60px;
}

.SandboxNotifierWrapper {
	background: $BlueSteel;
	border-radius: 5px;
}

.SandboxNotifierWrapper.Square {
	border-radius: 0px;
}

.SuccessNotifier {
	background: $OUIConfirmGreen;
}

.ClickableRow:hover {
	cursor: pointer;
	background: $LightGrey;
}


.NoticationCircle {
	background: $ExtraLightGrey;
	width: 20px;
	height: 20px;
	border-radius: 10px;
	font-size: $ExtraExtraSmallText;
	color: white;
	text-align: center;
	line-height: 20px;
}

.NoticationCircle.Small {
	width: 10px;
	height: 10px;
	border-radius: 5px;
}


.NoticationCircle.Unread {
	background: $OUIRelaxBlue;
}

.NoticationCircle.Dark {
	background: $OffBlack;
}



.NavNotificationsContainer {
	left: 50px;
	right: 50px;
	width: calc(100% - 100px);
	padding: 0px;
}

.Width33 {
	width: calc(33.3% - 20px);
	padding: 20px;
}

.Width33.Right {
	margin-left: 66.6%;
}

.ThreeWide {
	margin-left: 25px;
	margin-right: 25px;
}

.ThreeWide:first-child {
	margin-left: 0px;
}

.ThreeWide:last-child {
	margin-right: 0px;
}

.FloatingActionBar {
	display: flex;
	position: fixed;
	bottom: 30px;
}

.FloatingActionBar.Full {
	display: flex;
	position: fixed;
	bottom: 30px;
	left: calc(50% - 5px);
    transform: translateX(-50%);
	max-width: 1750px;
	width: calc(100% - 130px);
	border-radius: 30px;
}

.FloatingActionBar.Preview {
	right: 100px;
}

.Height5 {
	height: 5vh;
}

.Height10 {
	height: 10vh;
}

.Height20 {
	height: 20vh;
}

.Height075 {
	height: 7.5vh;
}

.Height15 {
	height: 15vh;
}

.Height30 {
	height: 30vh;
}




/* Photo */

.PhotoTileContainer {
	width: 250px;
	min-width: 250px;
	height: 300px;
}

.PhotoTileContainer.Wide {
	width: 300px;
	min-width: 300px;
}

.PhotoTileContainer.Thin {
	width: 125px;
	min-width: 125px;
	max-width: 125px;
	height: 150px;
}

.PhotoTileImage {
	width: 100%;
}

.DocumentPreviewImage {
	max-width: 90%;
	margin-left: 10%;
	max-height: 40vh;
}

.DocumentPreviewImage.FullWidth {
	max-width: 100%;
	margin-left: 0px;
}

.DocumentPreviewImage.ModalHeight {
	max-height: 55vh;
}




/* Sidebar */

.Sidebar {

}

.Sidebar.FixToTop {
	position: fixed;
	top: 100px;
}


.HideDesktop {
	display: none;
}





.NegativeSpaceAboveX2 {margin-top: -20px}

.SpaceAboveMinus100 {margin-top: -150px}

.SpaceAboveMinusX4 {margin-top: -40px}

.SpaceAboveHalf {margin-top: 5px}
.SpaceAbove {margin-top: 10px}
.SpaceAboveX2 {margin-top: 20px}
.SpaceAboveX3 {margin-top: 30px}
.SpaceAboveX4 {margin-top: 40px}
.SpaceAboveX5 {margin-top: 50px}
.SpaceAboveX6 {margin-top: 60px}
.SpaceAboveX6-5 {margin-top: 65px}
.SpaceAboveX8 {margin-top: 80px}
.SpaceAboveX9 {margin-top: 90px}
.SpaceAboveX10 {margin-top: 100px}
.SpaceAboveX12 {margin-top: 120px}
.SpaceAboveX15 {margin-top: 150px}
.SpaceAboveX16 {margin-top: 160px}
.SpaceAboveX17 {margin-top: 170px}
.SpaceAboveX20 {margin-top: 200px}

.SpaceBelowHalf {margin-bottom: 5px}
.SpaceBelow {margin-bottom: 10px}
.SpaceBelowX2 {margin-bottom: 20px}
.SpaceBelowX3 {margin-bottom: 30px}
.SpaceBelowX4 {margin-bottom: 40px}
.SpaceBelowX5 {margin-bottom: 50px}
.SpaceBelowX6 {margin-bottom: 60px}
.SpaceBelowX8 {margin-bottom: 80px}

.SpaceBelowX12 {margin-bottom: 120px}
.SpaceBelowX14 {margin-bottom: 140px}
.SpaceBelowX16 {margin-bottom: 160px}
.SpaceBelowX20 {margin-bottom: 200px}

.NegativeSpaceRight {margin-right: -10px}

.SpaceRight0 {margin-right: 0px}
.SpaceRightQtr {margin-right: 2px}
.SpaceRightHalf {margin-right: 5px}
.SpaceRight {margin-right: 10px}

.SpaceRightX2 {margin-right: 20px}
.SpaceRightX3 {margin-right: 30px}
.SpaceRightX25i {margin-right: 25px !important}

.SpaceRightX4 {margin-right: 40px}

.SpaceRightX5 {margin-right: 50px}
.SpaceRightX5i {margin-right: 50px !important}

.SpaceRightX75 {margin-right: 75px}

.SpaceRightX8 {margin-right: 80px}

.SpaceRightX10 {margin-right: 100px}
.SpaceRightX20 {margin-right: 200px}

.SpaceRight5pc {margin-right: 5%}

.SpaceLeftHalf {margin-left: 5px}

.NegativeSpaceLeft {margin-left: -10px}
.NegativeSpaceLeftX2 {margin-left: -20px}
.NegativeSpaceLeftX5 {margin-left: -50px}
.NegativeSpaceLeftX10 {margin-left: -100px}

.SpaceLeft {margin-left: 10px}

.SpaceLeftX2 {margin-left: 20px}

.SpaceLeftX2-5 {margin-left: 25px}

.SpaceLeftX3 {margin-left: 30px}
.SpaceLeftX4 {margin-left: 40px}

.SpaceLeftX5 {margin-left: 50px}
.SpaceLeftX5-5 {margin-left: 55px}

.SpaceLeftX6 {margin-left: 60px}

.SpaceLeftX10 {margin-left: 100px}
.SpaceLeftX12 {margin-left: 120px}
.SpaceLeftX16 {margin-left: 160px}
.SpaceLeftX20 {margin-left: 200px}

.SpaceLeftX30 {margin-left: 300px}

.SpaceLeftX32 {margin-left: 320px}

.SpaceBetween {justify-content: space-between}

.SpaceAround {justify-content: space-around}

.SpaceEvenly {justify-content: space-evenly}

.PaddingBelow {padding-bottom: 10px;}

.PaddingAbove {padding-top: 10px;}
.PaddingAboveX2 {padding-top: 20px;}
.PaddingAboveX4 {padding-top: 40px;}
.PaddingAboveX6 {padding-top: 60px;}


.PaddingRightHalf {padding-right: 5px;}
.PaddingRightX2 {padding-right: 20px;}
.PaddingRightX4 {padding-right: 40px;}
.PaddingRightX6 {padding-right: 60px;}


.PaddingLeftX2 {padding-left: 20px;}
.PaddingLeftX4 {padding-left: 40px;}
.PaddingLeftX5 {padding-left: 50px;}
.PaddingLeftX6 {padding-left: 60px;}
.PaddingLeftX8 {padding-left: 80px;}
.PaddingLeftX10 {padding-left: 100px;}


.PaddingBottomX2 {padding-bottom: 20px}
.PaddingBottomX4 {padding-bottom: 40px}
.PaddingBottomX8 {padding-bottom: 80px}
.PaddingBottomX14 {padding-bottom: 140px}
.PaddingBottomX16 {padding-bottom: 160px}








@media only screen and (min-width: 601px) {


	.FlexColumn {
		opacity: inherit;
		display: flex;
		min-width: 350px;
		max-width: 350px;
		flex-direction: column;
	}


	.PaddingRightX2-Desktop {padding-right: 20px;}
	.PaddingRightX4-Desktop {padding-right: 40px;}
	.PaddingRightX6-Desktop {padding-right: 60px;}
	.PaddingRightX10-dt {padding-right: 100px;}

	.SpaceRightX2-dt {margin-right: 20px}
	.SpaceRightX4-dt {margin-right: 40px}
	.SpaceRightX6-dt {margin-right: 60px}


	.PaddingLeftX2-Desktop {padding-left: 20px;}
	.PaddingLeftX4-Desktop {padding-left: 40px;}
	.PaddingLeftX6-Desktop {padding-left: 60px;}
	.PaddingLeftX10-dt {padding-left: 100px;}

	.SpaceLeftX2-dt {margin-left: 20px}
	.SpaceLeftX4-dt {margin-left: 40px}
	.SpaceLeftX6-dt {margin-left: 60px}

	.SpaceAbove-dt {margin-top: 10px}
	.SpaceAboveX2-dt {margin-top: 20px}
	.SpaceAboveX4-dt {margin-top: 40px}
	.SpaceAboveX6-dt {margin-top: 60px}


	.PaddingBottomX2-Desktop {padding-bottom: 20px}
	.PaddingBottomX4-Desktop {padding-bottom: 40px}
	.PaddingBottomX8-Desktop {padding-bottom: 80px}

	.SpaceBelowX2-dt {margin-bottom: 20px}
	.SpaceBelowX4-dt {margin-bottom: 40px}
	.SpaceBelowX6-dt {margin-bottom: 60px}

	.SlightRound {
		border-radius: 10px !important;
	}

	.SlightRound.Right {
		border-radius: 0 10px 10px 0px !important;
	}



	.Width30px {
		width: 30px;
	}

	.Width40px {
		width: 40px;
	}

	.Width50px {
		width: 50px;
	}

	.Height300px {
		height: 300px;
	}

	.FullWidth.Less61 {
		width: calc(100% - 61px)
	}

	.FullWidth.Less65 {
		width: calc(100% - 66px)
	}

	.FullWidth.Less69 {
		width: calc(100% - 74px)
	}









	.RowToColumnDivider {
		width: 30px;
	}









}







































@media only screen and (max-width: 600px) {

	h1, .NavH1 {
		font-size: $MediumText;
	}

	h2 {
		font-size: $SmallText;
		line-height: 25px;
	}

	h3, .NavH3, label {
		font-size: $SmallText;
	}

	p, a, li {
		font-size: $ExtraSmallText;
	}

	li {
		padding-bottom: 5px;
	}

	.FlexHeadingV2, .FlexInputV2 {
		width: 100%;
	}

	.FullWidthButton-Mobile {
		width: 100% !important;
		max-width: 100% !important;
		padding-left: 0px;
		padding-right: 0px;
	}

	.FlexColumn {
		display: flex;
		width: 100%;
		margin-right: 0px;
		flex-direction: column;
		justify-content: center;
	}

	.CentreMobile {
		text-align: center;
	}

	/* About */

	.ColumnOnMobile {
		flex-direction: column;
	}

	.LightWeightOnMobile {
		font-weight: 300;
	}


	.ColumnReverse-Mobile {
		flex-direction: column-reverse !important;
	}


	/* Footer */


	.FooterContainer {
		flex-direction: column;
	}

	.MobileSpaceAboveX2 {
		margin-top: 20px;
	}

	.Flex150 {
		flex: 1;
	}



	.HideMobile {
		display: none !important;
	}



	/* Nav buttons */

	.Wrap-Mobile {
		flex-wrap: wrap;
	}

	.FlexStart-Mobile {
		justify-content: flex-start;
	}

	.SpaceBetween-Mobile {
		justify-content: space-between;
	}

	.SpaceAround-Mobile {
		justify-content: space-around;
	}

	.AlignStart-Mobile {
		align-items: flex-start;
	}

	.TextCentre-Mobile {
		text-align: center;
	}

	.Width10 {
		width: 10%;
	}

	.Width15 {
		width: 15%;
	}

	.Width20 {
		width: 20%;
	}

	.Width25 {
		width: 25%;
	}

	.Width30 {
		width: 30%;
	}

	.x200 {
		max-width: 100px;
		min-width: 100px;
	}

	.Height100-mb {
		height: 100vh;
	}


	.FlexContainer.RowToColumn {
		flex-direction: column;
	}









	/* Icons */

	.V4SmallIcon, .V4ExtraSmallIcon {
		height: 20px;
		width: 20px;
		min-width: 20px;
	}











	.SpaceBelowX2-Mobile {margin-bottom: 20px}
	.SpaceBelowX4-Mobile {margin-bottom: 40px}
	.SpaceBelowX8-Mobile {margin-bottom: 80px}

	.SpaceAboveX2-Mobile {margin-top: 20px}
	.SpaceAboveX4-Mobile {margin-top: 40px}
	.SpaceAnoveX8-Mobile {margin-top: 80px}

	.SpaceRight-Mobile {margin-right: 10px}
	.SpaceRightX2-Mobile {margin-right: 20px}
	.SpaceRightX4-Mobile {margin-right: 40px}
	.SpaceRightX8-Mobile {margin-right: 80px}

	.SpaceLeft-Mobile {margin-left: 10px}
	.SpaceLeftX2-Mobile {margin-left: 20px}
	.SpaceLeftX4-Mobile {margin-left: 40px}
	.SpaceLeftX8-Mobile {margin-left: 80px}


	.PaddingLeftX2-Mobile {padding-left: 20px}


	.PaddingRightX2-Mobile {padding-right: 20px}






	/* Nav */

	.SLC {
		padding-left: 20px;
		padding-right: 20px;
	}

	.SLC.InternalSLC {
		width: calc(100% - 60px);
		margin-left: 20px;
	}




	.RowToColumnDivider {
		height: 20px;
	}













}







